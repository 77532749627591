import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/website/src/layouts/Resources.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Text mdxType="Text">ShadowContainer resources are coming soon.</Text>
    <Text mdxType="Text">
  Meantime, enjoy the{" "}
  <Link href="/components/shadow-container" newTab={false} mdxType="Link">
    Playground
  </Link>
  .
    </Text>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      